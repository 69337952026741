<template>
  <div class="howcan">
    <h1 class="header-title">Кто может стать членом Анонимных Наркоманов?</h1>
    <p class="text-block">
      Участие в Сообществе <em>бесплатно</em> и <em>анонимно</em>. Каждый из нас
      уже заплатил за право выздоравливать своей собственной болью.
    </p>
    <h2 class="header-title_sub">Единственное условие для членства</h2>
    <p class="text-block">
      Единственное условие для членства в АН – это желание прекратить
      употребление наркотиков. Членство не закрыто и для тех, кто употребляет
      какие-нибудь другие химические вещества, например алкоголь. Тот, кто
      чувствует, что у него есть проблемы с наркотиками, легальными или
      нелегальными, включая алкоголь, может прийти в АН. Выздоровление в АН
      базируется на признании проблемы с зависимостью в целом, а не на
      особенностях употребления какого-либо наркотика.
    </p>
    <h2 class="header-title_sub">Равенство</h2>
    <p class="text-block">
      <strong
        >Присоединиться к нам может каждый, независимо от возраста,
        национальности, сексуальной ориентации, убеждений, религии или
        отсутствия таковой.</strong
      >
      Собрания АН приветствуют любого зависимого, для которого наркотики стали
      серьезной проблемой. Неважно, откуда мы пришли, какое получили воспитание,
      и чем мы занимаемся – двери АН открыты для нас.
    </p>
    <h2 class="header-title_sub">Для независимых</h2>
    <p class="text-block">
      <strong
        >Если вы не являетесь зависимым, ищите «открытое собрание», на котором
        могут присутствовать люди, не являющиеся зависимыми (в нашем
        <router-link class="router-link" to="/timetable"
          >расписании</router-link
        >
        или по телефону, указанному на сайте).</strong
      >
    </p>
  </div>
</template>

<script>
export default {
  created() {
    document.title = "Кто может стать членом АН";
  },
};
</script>
